import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('.personaldata-form').on('submit-success', function(event, data) {
    $('#potwierdzenie-rejestracji').modal('show');
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: 'conversion',
            leadID: data.application
        });
    }
});

$('.application-form').on('submit-success', function(event, data) {
    $('.page').addClass('d-none');
    $('.page-game').removeClass('d-none');
    $('.result').addClass('d-none');

    $(`.result-${data.prize ? 'success' : 'failed'}`).removeClass('d-none');
    if (data.prize) {
        $('.result-prize-name').html(data.prize.name);
        $('.result-form-link').attr('href', data.formUrl);
    }
    setRegisteredData(data, 'section#application');
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

// $('[name="personal_data[noPesel]"]').on('change', function() {
//     $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
//     let fields = [
//         '[name="personal_data[street]"]',
//         '[name="personal_data[streetNr]"]',
//         '[name="personal_data[flatNr]"]',
//         '[name="personal_data[zipCode]"]',
//         '[name="personal_data[city]"]',
//     ];
//     $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
// }).trigger('change');

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[birthDay]"]',
        '[name="personal_data[extraData][citizenship]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));

    if ($(this).prop('checked')) {
        $('[name="personal_data[pesel]"]').prop('required',false);
        $(fields.join(', '), this.form.elements).closest('.form-row').removeClass('d-none');
    } else {
        $('[name="personal_data[pesel]"]').prop('required',true);
        $(fields.join(', '), this.form.elements).closest('.form-row').addClass('d-none');
    }
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    // setRegisteredData(data, 'section#winner');

    // page swap
    $('.page').addClass('d-none');
    $('.winner-form-container').removeClass('d-none');
    // $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    $('#potwierdzenie-laureata').modal('show');
});

$('.contact-form').on('submit-success', function(event, data) {
    setRegisteredData(data, 'section#contact');

    // page swap
    $('.page').addClass('d-none');
    $('.page-thank-you').removeClass('d-none');
    $('.page-thank-you').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-contact').modal('show');
});