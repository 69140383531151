window.$ = $;

import {
    OverlayScrollbars,
    ScrollbarsHidingPlugin,
    SizeObserverPlugin,
    ClickScrollPlugin
} from 'overlayscrollbars';

OverlayScrollbars.plugin(ClickScrollPlugin);

import { Modal } from 'bootstrap';

window.alert = function(message, type = 'danger') {
    let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `);
    $('.toast-container').prepend($toast);
    $toast.toast('show');
};

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

addNbsps('body');

$('[data-bs-toggle="tooltip"]').tooltip();

$.fn.initJs = function () {
    $(window).trigger('init', this);
    return this;
};
Object.defineProperty($, "init", {
    set(fn) {
        $(window).on('init', function(e, el) {
            fn(el, e);
        });
    }
});
window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// modal

$('.modal').on('show.bs.modal', function() {
    $('html').addClass('disable-scroll');
});

$('.modal').on('hide.bs.modal', function() {
    $('html').removeClass('disable-scroll');
});

// scrollbar

let mTextScroll;

$('.m-text__text-scroll').each(function(){
    mTextScroll = OverlayScrollbars($(this)[0], {
        scrollbars: {
            clickScroll: true
        }
    });
});

let mListNavTabs;

$('.m-list__nav, .m-list__table, .header-primary__nav-content').each(function(){
    mListNavTabs = OverlayScrollbars($(this)[0], {
        scrollbars: {
            clickScroll: true
        }
    });
});

$('[data-time]').each(function() {

    let that = $(this),
        countDownDate = new Date(that.data('time'));

    function getDistance() {

        let now = new Date().getTime(),
            distance = countDownDate - now,
            days = Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if(days < 10) {
            days = '0' + days;
        }

        if(hours < 10) {
            hours = '0' + hours;
        }

        if(minutes < 10) {
            minutes = '0' + minutes;
        }

        if(seconds < 10) {
            seconds = '0' + seconds;
        }

        that.find('.days').children('.value').html(days);
        that.find('.hours').children('.value').html(hours);
        that.find('.minutes').children('.value').html(minutes);
        that.find('.seconds').children('.value').html(seconds);

        if(distance < 0) {
            clearInterval(x);
            that.find('.cell').children('.value').html('00');
        }

    }

    var x = setInterval(function(){
        getDistance();
    }, 1000);

    getDistance();

});

$('.s-lottery__week-table table, .m-list__table table').each(function(){

    let that = $(this),
        thead = that.children('thead'),
        theadth = thead.find('th'),
        tbody = that.children('tbody'),
        tbodytr = tbody.find('tr');

    tbodytr.each(function(i){

        let tbodytrtd = $(this).find('td');

        tbodytrtd.each(function(i){

            $(this).attr('data-heading', $(theadth).eq(i).text());

        });

    });

});

function changeSizeModals() {

    $('.m-text').each(function(){

        let heading = $(this).find('.m-text__heading'),
            headingHeight = heading.outerHeight(),
            textScroll = $(this).find('.m-text__text-scroll'),
            scrollbar = $(this).find('.os-scrollbar.os-scrollbar-vertical');

        textScroll.css('padding-top', headingHeight / 10 + 'rem');
        scrollbar.css('top', headingHeight / 10 + 'rem');

    });

    $('.m-list').each(function(){

        let heading = $(this).find('.m-list__heading'),
            headingHeight = heading.outerHeight(),
            tableScroll = $(this).find('.m-list__table'),
            iconText = $(this).find('.m-list__icon-text'),
            scrollbar = $(this).find('.os-scrollbar.os-scrollbar-vertical');

        tableScroll.css('padding-top', headingHeight / 10 + 'rem');
        iconText.css('padding-top', headingHeight / 10 + 'rem');
        scrollbar.css('top', headingHeight / 10 + 'rem');

    });

}

let widthWindow = $(window).outerWidth();

if(widthWindow < 1200) {
    changeSizeModals();
}

$(window).resize(function(){

    let widthWindow = $(this).outerWidth();

    if(widthWindow < 1200) {
        changeSizeModals();
    }

});

$('.form-check-more-btn').click(function(e){

    e.preventDefault();

    $(this).hide().siblings('.form-check-more-content').fadeIn(200).css('display', 'block');

});

$('.form-check-more-content').click(function(e){

    e.preventDefault();

    $(this).show().siblings('.form-check-more-btn').removeAttr('style');
    $(this).css('display', 'none');

});